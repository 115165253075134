<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-04-16 21:56:12
 * @Description: 优惠券管理列表
-->
<template>
  <div class="padding24 color_white">
    <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item label="优惠券状态">
            <a-select
              v-model="couNoStatus"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              style="width: 120px"
              @change="couponStatusChange"
            >
              <a-select-option v-for="item in couponStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="优惠券名称">
            <a-input
              v-model="couName"
              placeholder="请输入优惠券名称"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <!-- <a-form-item label="创建人">
            <a-input
              v-model="createId"
              placeholder="请输入创建人"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item> -->
          <a-form-item label="商品ID">
            <a-input
              v-model="courseNo"
              placeholder="请输入商品ID"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
            >
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-form>
        <a-form-item>
          <a-button
            icon="plus"
            type="primary"
            @click="addCoupon"
            v-show="isShowAdd"
          >
            添加优惠券
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        :scroll="{ x: 2000, y: 550 }"
      >
        <span slot="couStatus" slot-scope="couStatus, record">
          {{ couponStatusMap[couStatus] }}
        </span>
        <span slot="releaseStatus" slot-scope="releaseStatus, record">
          {{ releaseStatusMap[releaseStatus] }}
        </span>
        <div slot="canUserCourse" slot-scope="canUserCourse, record">
          <p v-for="item, idx in canUserCourse" :key="idx">{{ item.name }}</p>
        </div>
        <span slot="operation" slot-scope="operation, record">
          <a
            class="margin_right20"
            v-show="record.releaseStatus == 1"
            @click="releaseStatusConfirm(record)"
          >
            取消发布
          </a>
          <a
            class="margin_right20"
            v-show="record.releaseStatus == 2"
            @click="releaseStatusConfirm(record)"
          >
            发布
          </a>
          <a class="margin_right20" @click="gotoUsedDetail(record)">使用详情</a>
          <a class="margin_right20" @click="gotoCouponDetail(record)">查看</a>
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      ></MyPagination>
    </a-spin>
    <router-view v-else />
  </div>
</template>

<script>
import moment from "moment";
import {
  CouponManageListApi,
  UpdateCouponStatusApi,
} from "@/request/api/marketingCenter";
import MyPagination from "@/components/pagination/MyPagination";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";

export default {
  components: {
    MyPagination,
  },
  created() {
    this.getCouponListFn();
    this.handleCouponStatusArr();
    const dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    if (codeFn("/adminv2/addCoupon")) this.isShowAdd = true;
    if (codeFn("/adminv2/getCouponUseInfo")) this.isShowUsedDetail = true;
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: "",
      pageNo: 1,
      pageSize: 20,
      count: 0,
      isDisableSearch: false, // 搜索是否禁用
      isShowAdd: true, // 是否展示新增优惠券按钮
      isShowUsedDetail: true, // 是否展示查看使用详情入口
      tableLoading: true,
      tableData: [],
      columns: [
        {
          title: "优惠券ID",
          fixed: "left",
          dataIndex: "couNo",
          key: "couNo",
          width: 200,
          // scopedSlots: { customRender: "couNo" }
        },
        {
          title: "优惠券名称",
          fixed: "left",
          dataIndex: "couName",
          key: "couName",
          width: 200,
          // scopedSlots: { customRender: "couName" }
        },
        {
          title: "金额",
          dataIndex: "price",
          key: "price",
          width: 100,
          // scopedSlots: { customRender: "price" }
        },
        {
          title: "适用商品",
          dataIndex: "canUserCourse",
          key: "canUserCourse",
          scopedSlots: { customRender: "canUserCourse" }
        },
        {
          title: "生效时间",
          dataIndex: "cTime",
          key: "cTime",
          width: 150,
          // scopedSlots: { customRender: "cTime" }
        },
        {
          title: "失效时间",
          dataIndex: "eTime",
          key: "eTime",
          width: 150,
          // scopedSlots: { customRender: "eTime" }
        },
        {
          title: "发行量",
          dataIndex: "couponCirculation",
          key: "couponCirculation",
          width: 100,
          // scopedSlots: { customRender: "couponCirculation" }
        },
        {
          title: "已领取",
          dataIndex: "received",
          key: "received",
          width: "100px",
          // scopedSlots: { customRender: "received" }
        },
        {
          title: "已使用",
          dataIndex: "used",
          key: "used",
          width: "100px",
          // scopedSlots: { customRender: "used" }
        },
        {
          title: "创建人",
          dataIndex: "createName",
          key: "createName",
          width: "100px",
          // scopedSlots: { customRender: "createName" }
        },
        {
          title: "状态",
          dataIndex: "couStatus",
          key: "couStatus",
          width: 100,
          scopedSlots: { customRender: "couStatus" },
        },
        {
          title: "发布状态",
          dataIndex: "releaseStatus",
          key: "releaseStatus",
          width: 100,
          scopedSlots: { customRender: "releaseStatus" }
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
        },
      ],
      couponStatusMap: {
        0: "全部",
        1: "未开始",
        2: "领取中",
        3: "已领完",
        4: "已过期",
      },
      releaseStatusMap: {
          1: '发布',
          2: '取消发布'
      },
      couponStatusArr: [],
      couNoStatus: 0,
      couName: "",
      // createId: "",
      courseNo: "",
    };
  },
  methods: {
    moment,
    handleCouponStatusArr() {
      const obj = this.couponStatusMap;
      this.couponStatusArr = Object.keys(obj).map((item) => {
        return {
          key: +item,
          value: obj[item],
        };
      });
      console.log("## this.couponStatusArr", this.couponStatusArr);
    },
    // 发布/取消发布状态变更
    updateCouponStatus(record) {
      UpdateCouponStatusApi({
        couNo: record.couNo
      }).then(({code, msg}) => {
        if (code === 200) {
          this.getCouponListFn();
        } else {
          message.error(msg || "操作失败，请稍后重试");
        }
      });
    },

    // 发布/取消发布弹窗
    releaseStatusConfirm(record) {
        const text = record.releaseStatus === 1 ? '取消发布' : '发布';
        Modal.confirm({
          title: "提示",
          content: `确认${text}吗`,
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk: () => {
            this.updateCouponStatus(record);
          }
        });
    },
    // 添加优惠券跳转按钮
    addCoupon() {
      this.$router.push("/marketingCenter/couponManage/addCoupon/0");
    },
    // 使用详情跳转
    gotoUsedDetail(record) {
      this.$router.push(`/marketingCenter/couponManage/usedDetail/${record.couNo}`);
    },
    // 查看优惠券详情跳转
    gotoCouponDetail(record) {
      this.$router.push(`/marketingCenter/couponManage/viewCoupon/${record.couNo}`);
    },
    // 获取优惠券列表的数据
    getCouponListFn() {
      this.tableLoading = true;
      CouponManageListApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couNoStatus: +this.couNoStatus,
        couName: this.couName,
        // createId: this.createId,
        courseNo: this.courseNo,
      })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableLoading = false;
            this.tableData = data.data;
            this.count = data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 优惠券状态改变的回调
    couponStatusChange(value) {
      this.pageNo = 1;
      this.couNoStatus = value;
      this.getCouponListFn();
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.getCouponListFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getCouponListFn();
    },
  },
};
</script>